import React from 'react';
import Section from '@latitude/section';
import { Link } from '@latitude/link';
import Icon from '@latitude/icon';
import { SPAN, TEXT_TYPE, ICON_VARIANT } from '@/utils/constants';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import './_promo_banner.scss';

const Promo = ({
  layout,
  promoCards,
  backgroundColor,
  headingSize = 'Heading 3',
  alignment
}) => {
  const colSpan = SPAN[layout];
  const getButtonType = (theme, darkTheme) => {
    if (backgroundColor === 'Dark') {
      if (darkTheme === 'White') return BUTTON_STYLE.SECONDARY_INVERSE;
      return BUTTON_STYLE.PRIMARY;
    } else {
      if (theme === 'Blue') return BUTTON_STYLE.SECONDARY;
      return BUTTON_STYLE.PRIMARY;
    }
  };
  const Typography = TEXT_TYPE[headingSize];
  const OPTIONS = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography>{children}</Typography>
      )
    }
  };
  const OPTIONS_DISCLAIM = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <div className="promo-card-disclaimer">{children}</div>
      )
    }
  };
  return (
    <div className="promo-banner">
      <Section
        className={`promo-section common_tag_style promo-bg-${backgroundColor?.toLowerCase()}`}
      >
        <div className="row">
          {promoCards?.map(promoCard => (
            <div
              key={promoCard?.id}
              className={`col-12 col-sm-12 col-md-${colSpan} promo-card-span-${colSpan}`}
            >
              <div
                className={`promo-card-img order-${alignment?.toLowerCase()}`}
              >
                <img
                  src={promoCard?.image?.file?.url}
                  alt={promoCard?.image?.file?.fileName}
                />
              </div>
              <div className="promo-card-content">
                {promoCard?.headingCopy &&
                  documentToReactComponents(promoCard?.headingCopy, OPTIONS)}
                {promoCard?.description &&
                  documentToReactComponents(promoCard?.description)}
                {promoCard?.primaryButton?.url && (
                  <div className="promo-actions-wrapper">
                    <Link
                      className={`promo-action-primary`}
                      button={getButtonType(
                        promoCard?.primaryButtonTheme,
                        promoCard?.primaryButtonDarkTheme
                      )}
                      href={promoCard?.primaryButton?.url}
                      target="_self"
                      trackId="get-started"
                      trackEventData={{
                        category: 'cta',
                        action: 'quote-link',
                        location: 'home'
                      }}
                      trackProductId={['PLAULF-WEB']}
                    >
                      {promoCard?.primaryButton?.text}
                    </Link>
                    {promoCard?.linkButton?.url && (
                      <Link
                        className={`promo-action-tertiary ${
                          backgroundColor === 'Dark'
                            ? 'promo-action-tertiary-dark'
                            : 'promo-action-tertiary-light'
                        }`}
                        button={BUTTON_STYLE.TERTIARY}
                        href={promoCard?.linkButton?.url}
                        target="_blank"
                        trackId="get-started"
                        trackEventData={{
                          category: 'cta',
                          action: 'quote-link',
                          location: 'home'
                        }}
                        trackProductId={['PLAULF-WEB']}
                      >
                        {promoCard?.linkButton?.text}
                        <Icon variant={ICON_VARIANT.CHEVRON_RIGHT} />
                      </Link>
                    )}
                  </div>
                )}

                {promoCard?.disclaimer &&
                  documentToReactComponents(
                    promoCard?.disclaimer,
                    OPTIONS_DISCLAIM
                  )}
              </div>
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
};

export default Promo;
