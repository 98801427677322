// @flow

import React from 'react';
import styled, { css } from 'styled-components';
import ReactHTMLParser from 'react-html-parser';
import { Heading3 } from '@latitude/heading';
import { Text } from '@latitude/text';
import { BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import Section from '@latitude/section';

type PromoProps = {
  title: React.Node | string,
  description?: string,
  frameAlignment?: string,
  frameImage?: React.Node | string,
  frameBackgroundColor?: string,
  frameBorderColor?: string,
  verticalAlignContent: boolean,
  ctaButtons?: React.Node
};

const PromoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINT.LG}) {
    flex-direction: row;
  }
`;

const PromoFrameContainer = styled.div`
  ${({ bgColor }: PromoProps) =>
    bgColor &&
    css`
      background-color: ${bgColor};
    `};
  position: relative;
  width: 242px;
  height: 215px;
  margin: 0 auto 45px auto;
  ${({ alignment }: PromoProps) =>
    alignment === 'left'
      ? css`
          transform: translateX(23px);
        `
      : css`
          transform: translateX(-23px);
        `};

  @media (min-width: ${BREAKPOINT.LG}) {
    width: 390px;
    height: 346px;
    transform: none;
    margin: ${({ alignment }: PromoProps) =>
      alignment === 'left' ? '0 0 45px 45px' : '0 45px 45px 0'};
    order: ${({ alignment }: PromoProps) => (alignment === 'left' ? 1 : 2)};
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;

    @media (min-width: ${BREAKPOINT.LG}) {
      width: 100%;
      height: 100%;
    }
  }

  img {
    width: 242px;
    height: 215px;
    display: block;
    margin: 0;

    @media (min-width: ${BREAKPOINT.LG}) {
      width: 390px;
      height: 346px;
    }
  }

  &:before {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 45px;

    ${({ alignment }: PromoProps) =>
      alignment === 'left'
        ? css`
            left: -45px;
          `
        : css`
            right: -45px;
          `};
    border: solid 30px ${({ borderColor }: PromoProps) => borderColor};
    z-index: -1;
  }
`;

const PromoButtons = styled.div`
  margin: 16px auto 0;
  .button {
    max-width: 156px;
    margin-right: 16px;

    ${({ alignment }: PromoProps) =>
      alignment === 'right' &&
      css`
        margin-left: auto;
      `};
  }

  > div {
    display: flex;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    ${({ alignment }: PromoProps) =>
      alignment === 'left'
        ? css`
            margin-left: 0;
            margin-right: 0;
          `
        : css`
            margin-left: auto;
            margin-right: 0;
            text-align: right;
          `};
  }
`;

const PromoContentContainer = styled.div`
  padding: 24px 0;
  text-align: center;

  ${({ verticalAlign }: PromoProps) =>
    verticalAlign &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 1 0%; /* using % for flex-basis as workaround to Safari and IE11 flex bugs */
    `} h3 {
    font-size: 32px;
    line-height: 1.13;
    margin-bottom: 16px;
    color: ${COLOR.BLACK};
    text-align: center;

    @media (min-width: ${BREAKPOINT.LG}) {
      font-size: 40px;
      line-height: 1.2;
      text-align: ${({ alignment }: PromoProps) =>
        alignment === 'left' ? 'left' : 'right'};
    }
  }

  p {
    color: ${COLOR.GREY75};
    text-align: center;

    @media (min-width: ${BREAKPOINT.SM}) {
      max-width: 320px;
      margin: 0 auto;
    }

    @media (min-width: ${BREAKPOINT.LG}) {
      margin: 0;
      max-width: 420px;
      text-align: ${({ alignment }: PromoProps) =>
        alignment === 'left' ? 'left' : 'right'};
    }
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 32px;
    text-align: left;
    order: ${({ alignment }: PromoProps) => (alignment === 'left' ? 2 : 1)};
  }
`;

const Promo = ({
  frameImage,
  description,
  title,
  frameAlignment,
  frameBorderColor,
  frameBackgroundColor,
  verticalAlignContent,
  ctaButtons,
  footerText
}: PromoProps) => (
  <PromoContainer>
    {frameImage && (
      <PromoFrameContainer
        alignment={frameAlignment}
        borderColor={frameBorderColor}
        bgColor={frameBackgroundColor}
      >
        {frameImage}
      </PromoFrameContainer>
    )}
    <PromoContentContainer
      verticalAlign={verticalAlignContent}
      alignment={frameAlignment}
    >
      <Heading3>{title}</Heading3>
      {description && (
        <Text fontSize="18px">{ReactHTMLParser(description)}</Text>
      )}
      {ctaButtons && (
        <PromoButtons alignment={frameAlignment}>{ctaButtons}</PromoButtons>
      )}
      {footerText && (
        <Text css="line-height: 1.3; padding-top 10px" fontSize="12px">
          {ReactHTMLParser(footerText)}
        </Text>
      )}
    </PromoContentContainer>
  </PromoContainer>
);

export const PromoSection = styled(Section)`
  && {
    z-index: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    .section__content {
      max-width: 950px;
    }
    @media (min-width: ${BREAKPOINT.LG}) {
      padding-top: 72px;
      padding-bottom: 72px;
    }
  }
`;

export default Promo;
