// @flow
import styled from 'styled-components';
import React, { useEffect, useContext }  from 'react';
import Helmet from 'react-helmet';
import { Hero } from '@latitude/hero';
import { BrandedCallout } from '@latitude/callout';
import {
  BREAKPOINT,
  BUTTON_STYLE,
  MARGIN
} from '@latitude/core/utils/constants';
import SvgInline from '@latitude/svg-inline/';
import { Text } from '@latitude/text';
import { Link } from '@latitude/link';
import Promo, { PromoSection } from '../components/Promo/Promo';
import OfferTiles from '@/components/OfferTiles/OfferTiles';
import Layout from '../components/layout';
import { PageContext } from '@/context/PageContext';
import PromoBanner from '@/components/lab-components/PromoBanner';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import { useSetState } from '@/utils/hooks';
import './credit-cards/gem-visa-card.scss';

const Subheading = styled(Text)`
  max-width: 800px;
  margin: 0 auto ${MARGIN.M32};
  text-align: center;
`;

const createMailtoLink = () => {
  const email =
    'brad.graham@latitudefinancial.com;sash.winspear@latitudefinancial.com;';
  const subject = 'We’d like to understand more about the program';
  const body = `Thank you for contacting us, please provide your details and we’ll reply ASAP.`;
  return `mailto:${email}?subject=${subject}&body=${body}`;
};
const GemVisaOfferPage = () => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    heroBannerData: [],
    promoBannerData: []
  });

  useEffect(() => {
    let [promoBanners, heroBanners] = [[],[]];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.PROMO_BANNER:
          promoBanners = [...promoBanners, item];
          break;
        case CONTENTFUL_COMPONENTS.HERO_BANNER_CAROUSEL:
          heroBanners = [...heroBanners, item];
          break;
        default:
          break;
      }
    });
    setState({
      promoBannerData:
        promoBanners.length > 0 ? promoBanners : state.promoBannerData,
      heroBannerData:
        heroBanners.length > 0 ? heroBanners : state.heroBannerData
    });
  }, [contentfulPageData]);
  /** - END - */

  return (
    <Layout>
      <main className="navigation-spacer car-loan-page">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.gemfinance.co.nz/loans/car-loans/"
          />
          <title>Gem Visa Offers | Apply now | Gem by Latitude</title>
          <meta
            name="description"
            content="Gem Visa lets you shop for whatever you want, anywhere Visa is accepted, worldwide."
          />
        </Helmet>
        {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
        <Hero
          css={`
            && {
              background: #0146aa;
              background: linear-gradient(135deg, #0146aa 0%, #0162ed 100%);
              color: #fff;

              h1,
              div,
              p {
                color: #fff;
              }

              .HeroContent {
                [class^='Linkstyled__StyledLink-'] {
                  color: #0061ee !important;
                  background-color: #fff !important;
                  border-color: transparent !important;

                  &:hover {
                    box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 13px 0px;
                    transition: all 150ms ease-in-out 0s;
                    border-bottom: 1px solid rgb(0, 106, 255);
                    text-shadow: rgb(0, 106, 255) 0px 0px 1px;
                    background-color: #cee6ff !important;
                    border-color: #cee6ff;
                  }
                }
              }

              @media (max-width: ${BREAKPOINT.MD}) {
                h1,
                div,
                p {
                  color: #fff !important;
                }

                .HeroContent {
                  padding: 10px;
                  background-color: transparent;
                }
              }
            }
          `}
          imageContent={
            <div
              id="hero"
              css={`
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (min-width: ${BREAKPOINT.LG}) {
                  justify-content: flex-end;
                }
              `}
            >
              <img
                src={require('../images/gem-visa-card-banner.webp')}
                css={`
                  height: 280px;
                  width: auto;
                  margin: 0;
                  @media (min-width: ${BREAKPOINT.MD}) {
                    height: 400px;
                    margin: 70px -60px 0 0;
                  }
                  @media (min-width: ${BREAKPOINT.LG}) {
                    height: 555px;
                    margin: 0 -90px 0 0;
                  }
                `}
                alt="Low Rate Card"
              />
            </div>
          }
          title={["Gotta have it?<br />Let's make it happen."]}
          text={[
            'Enjoy 6 months interest free on everyday purchases of $250 or more*.'
          ]}
          buttonProps={{
            trackId: 'hero-apply-now',
            href: 'https://cards.gemvisa.co.nz/',
            children: 'Apply Now',
            width: '160px',
            trackEventData: {
              category: 'cta',
              action: 'application-link'
            }
          }}
          footnote={[
            <sup>*</sup>,
            'Gem Visa T&Cs and fees apply including a $55 establishment fee and $55 annual fee (charged $27.50 half-yearly). Prevailing interest rate (currently 29.49% p.a.) applies to any remaining balance on the expiry of the interest free term. For cash advances, an interest rate of 29.95% p.a. and a $2 fee applies. Further information on rates and fees can be found at gemfinance.co.nz. Available to approved Gem Visa credit card customers only. Credit provided by Latitude Financial Services Ltd'
          ]}
        />)}
        <OfferTiles css={``} />
        {state?.promoBannerData?.[0] ? (
          <PromoBanner {...state?.promoBannerData[0]} />
        ) : (
        <PromoSection>
          <Promo
            title={
              <>
                Let&apos;s shop with <br />
                Gem Visa.
              </>
            }
            description="Enjoy 6 months interest free on all purchases of $250 or more<sup>*</sup>, anywhere Visa is accepted."
            footerText="<br />*&nbsp;Gem Visa T&amp;Cs and fees apply including a $55 establishment fee and $55 annual fee (charged $27.50 half-yearly). Prevailing interest rate (currently 29.49% p.a.) applies to any remaining balance on the expiry of the interest free term. For cash advances, an interest rate of 29.95% p.a. and a $2 fee applies. Available to approved Gem Visa credit card customers only. Credit provided by Latitude Financial Services Ltd."
            frameImage={<SvgInline name="cardless-payment" />}
            frameAlignment="left"
            frameBackgroundColor="white"
            frameBorderColor="#b1dbff"
            ctaButtons={
              <Link
                href="/credit-cards/gem-visa-card/"
                button={BUTTON_STYLE.SECONDARY}
                trackId="gemvisa-banner__find-out-more"
                trackEventData={{
                  category: 'button-link',
                  action: 'internal-link',
                  location: "Let's shop with Gem Visa."
                }}
                trackProductId={['CCNZGEM']}
                css={`
                  && {
                    @media (max-width: ${BREAKPOINT.LG}) {
                      margin-left: auto;
                      margin-right: auto;
                    }
                  }
                  background: #0046aa !important;
                  border-color: #0046aa !important;
                  max-width: 156px;
                `}
              >
                Find out more
              </Link>
            }
          />
        </PromoSection>)
        }
        <BrandedCallout
          hasConfettiBackground
          moreVerticalPadding
          heading="We’re taking on new retailers now"
          line1="Growth. No integration. New Customers. Zero merchant fees. Ready now. "
          cta={
            <Link
              trackId="talk-to-our-team"
              trackEventData={{
                location: 'Talk to our team!'
              }}
              href={createMailtoLink()}
              variant="secondary"
              css="margin-top: 16px;"
              button={BUTTON_STYLE.SECONDARY}
            >
              Talk to our team!
            </Link>
          }
        />
      </main>
    </Layout>
  );
};

export default GemVisaOfferPage;
